import { Link, graphql, useStaticQuery } from 'gatsby';
import parseText from 'html-react-parser';
import { parse } from 'node-html-parser';
import React, { useCallback, useEffect, useState } from 'react';

const RatesSection = ({ module }) => {
  const ratesQuery = useStaticQuery(graphql`
    query getRates {
      wp {
        globalRates {
          ratesContent {
            rates {
              year
              rate {
                link {
                  url
                  target
                  title
                }
                linkType
              }
            }
          }
        }
      }
    }
  `);

  const rateContent = ratesQuery?.wp?.globalRates?.ratesContent;
  const [selectedYear, setSelectedYear] = useState('2024');

  return (
    <>
      <section id="RatesSection" className="rates-table mdscreen:mt-20">
        <div className="container-fluid-md">
          <div className="max-w-600 m-auto  text-center">
            <ul className="tabs justify-center mb-0 inline-block">
              {rateContent.rates &&
                rateContent.rates.map((item, i) => {
                  return (
                    <li
                      key={i}
                      onClick={() => setSelectedYear(item.year)}
                      className={`tab-link cursor-pointer text-dark uppercase font-400 text-12 font-preheaders ${
                        selectedYear == item.year ? 'tab-current' : ''
                      }`}
                    >
                      {item.year}
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="rate-links w-full bg-white lg:px-120 px-60 smscreen2:px-20 lg:py-60 py-20 mt-40 mb-100 mdscreen:mb-60">
            {rateContent.rates
              .filter((rate) => rate.year === selectedYear)
              .map((item, index) => (
                <div key={index}>
                  {item.rate.map((rateMap, i) => (
                    <a
                      key={i}
                      href={rateMap.link.url}
                      target={rateMap.link.target}
                      className="rate-link bg-transparent text-26 smscreen2:text-14 outline-none font-heading text-black-100 relative p-0 mb-15 border-b-[0.8px] border-solid border-l-0 border-r-0 border-t-0 border-opacity-40 border-dark pb-10 smscreen2:pr-0 flex justify-between items-center gap-[20px] smscreen2:gap-[10px] my-[20px]"
                    >
                      <span className="">{parseText(rateMap.link.title)}</span>
                      <div className="text-12 font-body uppercase flex items-center justify-center gap-[12px]">
                        {rateMap.linkType === 'download' ? (
                          <>
                            <img
                              className="max-w-[15px]"
                              src="/images/download.png"
                            />
                            <span className="text-black-300 uppercase text-12 pl-10">
                              Download
                            </span>
                          </>
                        ) : (
                          <>
                            <img
                              className="max-w-[13px]"
                              src="/images/new-tab-icon.png"
                            />
                            <span className="text-black-300 uppercase text-12 pl-10">
                              View
                            </span>
                          </>
                        )}
                      </div>
                    </a>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default RatesSection;

export const RatesSectionFragment = graphql`
  fragment RatesSectionFragment on WpPage_Flexiblecontent_FlexibleContent_RatesSection {
    fieldGroupName
    propertyRates {
      ... on WpPage_Flexiblecontent_FlexibleContent_RatesSection_PropertyRates_GlobalRates {
        fieldGroupName
        hideFilter
      }
    }
  }
`;

export const RatesSectionPropertyFragment = graphql`
  fragment RatesSectionPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_RatesSection {
    fieldGroupName
    propertyRates {
      ... on WpProperty_Flexiblecontent_FlexibleContent_RatesSection_PropertyRates_GlobalRates {
        fieldGroupName
        hideFilter
      }
    }
  }
`;
